import React from 'react'

export const RootLoader = ({ step }) => (
  // Css for .root-loader in public/index.html
  <div className="root-loader" />
  // <div className="root-loader">
  //   <br />
  //   Loading {step}
  // </div>
)

export default RootLoader
