import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import Root from './Root/Root'
import * as serviceWorker from './serviceWorker'

// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update');
//   whyDidYouUpdate(React, {include: [/Global/, /Sidebar/]});
// }

ReactDOM.render(<Root />, document.getElementById('root'))

serviceWorker.unregister()
window.updateAvailable = new Promise(() => {})
// window.updateAvailable = new Promise(resolve => {
//   serviceWorker.register({
//     onUpdate: registration => {
//       if (!navigator.serviceWorker.controller) {
//         // The window client isn't currently controlled so it's a new service
//         // worker that will activate immediately
//         return
//       }

//       // When the service worker is swapped out (skipWaiting) we'll need to reload the window
//       var preventDevToolsReloadLoop
//       navigator.serviceWorker.addEventListener('controllerchange', function(
//         event
//       ) {
//         // Ensure refresh is only called once.
//         // This works around a bug in "force update on reload".
//         if (preventDevToolsReloadLoop) return
//         preventDevToolsReloadLoop = true
//         window.location.reload()
//       })

//       // Watch for new service workers
//       onNewServiceWorker(registration, () => {
//         // resolve promise to a function that will update the app
//         resolve(() => {
//           if (!registration.waiting) {
//             // Just to ensure registration.waiting is available before
//             // calling postMessage()
//             return
//           }

//           registration.waiting.postMessage('skipWaiting')
//         })
//       })
//     }
//   })

//   function onNewServiceWorker(registration, callback) {
//     if (registration.waiting) {
//       // SW is waiting to activate. Can occur if multiple clients open and
//       // one of the clients is refreshed.
//       return callback()
//     }

//     function listenInstalledStateChange() {
//       registration.installing.addEventListener('statechange', function(event) {
//         if (event.target.state === 'installed') {
//           // A new service worker is available, inform the user
//           callback()
//         }
//       })
//     }

//     if (registration.installing) {
//       return listenInstalledStateChange()
//     }

//     // We are currently controlled so a new SW may be found...
//     // Add a listener in case a new SW is found,
//     registration.addEventListener('updatefound', listenInstalledStateChange)
//   }
// })

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept()
}
