const embeds = require('./embeds')

const variables = {
  routes: {
    board:
      '/:boardName([^./]+)' +
      '\\.b:boardId([^./]+)' +
      ':shareId(\\.s[^./]+)?' +
      ':embed(\\.embed)?',
    block:
      '/:boardName([^./]+)' +
      '\\.b:boardId([^./]+)' +
      '/:blockId([^./]+)' +
      ':shareId(\\.s[^./]+)?' +
      ':embed(\\.embed)?',
    folder:
      '/:folderName([^./]+)' +
      '\\.f:folderId([^./]+)' +
      ':shareId(\\.s[^./]+)?' +
      ':embed(\\.embed)?'
  },

  smallScreen: 600,

  // welcomeBoard: {
  //   id: '-KrYvZdER4irL8Zd-Ypi',
  //   name: 'Welcome to Wiip'
  // },

  // Number of cols in a board
  // 35 Cols allows 2,3,4 column layouts with gutters
  // 8 +1+ 8 +1+ 8 +1+ 8 = 35
  // 11 +1+ 11 +1+ 11 = 35
  // 17 +1+ 17 = 35
  // Add 2 for external gutters
  cols: 35 + 2, // Update in css too!

  textSizes: [0.4, 0.6, 1, 2, 3],

  snapThreshold: 0.2, // cols

  newBoardSectionHeight: 35, // height of first section in a new board
  newSectionHeight: 20, // Height of a section inserted

  defaultBlockLimit: 10000,

  notificationTime: 5, // seconds

  placeholders: {
    blur: 20, // px
    downscale: 60, // times smaller
    fadeTime: 1 // sec
  },

  sentryDsn: 'https://0c052d3afc334a749ffd0147d27c662c@sentry.io/269833',

  locales: {
    en: { translation: {} }
  },

  analytics: { app: 'UA-117917294-2', website: 'UA-117917294-4' },

  emailRegex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,

  embeds
}

const config = process.env.REACT_APP_CONFIG

if (config === 'production') {
  variables.firebaseConfig = {
    apiKey: 'AIzaSyBNqcIx1wkbzlJbFYPs7MJ7jsh8w-Kk8wI',
    authDomain: 'beta.wiip.co',
    databaseURL: 'https://bricks-production.firebaseio.com',
    projectId: 'bricks-production',
    storageBucket: 'bricks-production.appspot.com',
    messagingSenderId: '529020229214'
  }
  variables.imgixRedirect =
    'https://us-central1-bricks-production.cloudfunctions.net/imgixRedirect'
  variables.acceptShare = 'https://wiip.co/acceptShare'
  variables.braintree = {
    clientToken: '/braintreeClientToken',
    cancel: '/braintreeCancelSub',
    subscription: '/braintreeSubscription'
  }
  variables.locales.en.translation = {
    environment: 'production',
    environmentQualifier: ''
  }
  variables.analytics = { app: 'UA-117917294-1', website: 'UA-117917294-6' }
} else if (config === 'X-true-staging') {
  // staging with staging DB
  variables.firebaseConfig = {
    apiKey: 'AIzaSyAvRmIxAlwWM1khS9xFvpdOl7ECnM9Vrok',
    authDomain: 'staging-2x.wiip.co',
    databaseURL: 'https://bricks-staging.firebaseio.com',
    projectId: 'bricks-staging',
    storageBucket: 'bricks-staging.appspot.com',
    messagingSenderId: '731198366536'
  }
  variables.imgixRedirect =
    'https://us-central1-bricks-staging.cloudfunctions.net/imgixRedirect'
  variables.acceptShare = 'https://staging-2x.wiip.co/acceptShare'
  variables.braintree = {
    clientToken:
      'https://us-central1-bricks-staging.cloudfunctions.net/braintreeClientToken',
    subscription:
      'https://us-central1-bricks-staging.cloudfunctions.net/braintreeSubscription'
  }
  variables.locales.en.translation = {
    baseUrl: 'https://staging-2x.wiip.co',
    environment: 'staging',
    environmentQualifier: ' (staging)'
  }
  variables.analytics = { app: 'UA-117917294-3', website: 'UA-117917294-5' }
} else if (config === 'staging') {
  // staging with live DB
  variables.firebaseConfig = {
    apiKey: 'AIzaSyBNqcIx1wkbzlJbFYPs7MJ7jsh8w-Kk8wI',
    authDomain: 'beta.wiip.co',
    databaseURL: 'https://bricks-production.firebaseio.com',
    projectId: 'bricks-production',
    storageBucket: 'bricks-production.appspot.com',
    messagingSenderId: '731198366536'
  }
  variables.imgixRedirect =
    'https://us-central1-bricks-production.cloudfunctions.net/imgixRedirect'
  variables.acceptShare = 'https://wiip.co/acceptShare'
  variables.braintree = {
    clientToken: '/braintreeClientToken',
    cancel: '/braintreeCancelSub',
    subscription: '/braintreeSubscription'
  }
  variables.locales.en.translation = {
    baseUrl: 'https://staging-2x.wiip.co',
    environment: 'staging',
    environmentQualifier: ' (staging)'
  }
  variables.analytics = { app: 'UA-117917294-3', website: 'UA-117917294-5' }
} else if (config === 'development') {
  variables.firebaseConfig = {
    apiKey: 'AIzaSyBb021PL_JjUJd5VhKz8rFw4g3PDbwmLM4',
    authDomain: 'dev-1Z.wiip.co',
    databaseURL: 'https://bricks-dev.firebaseio.com',
    projectId: 'bricks-dev',
    storageBucket: 'bricks-dev.appspot.com',
    messagingSenderId: '348295248891'
  }
  variables.imgixRedirect =
    'https://us-central1-bricks-dev.cloudfunctions.net/imgixRedirect'
  variables.acceptShare = 'https://dev-1z.wiip.co/acceptShare'
  variables.braintree = {
    clientToken: '/braintreeClientToken',
    cancel: '/braintreeCancelSub',
    subscription: '/braintreeSubscription'
  }
  variables.locales.en.translation = {
    baseUrl: 'https://dev-1z.wiip.co',
    environment: 'dev',
    environmentQualifier: ' (dev)'
  }
  variables.analytics = { app: 'UA-117917294-2', website: 'UA-117917294-4' }
} else {
  // Local dev
  variables.firebaseConfig = {
    apiKey: 'AIzaSyBb021PL_JjUJd5VhKz8rFw4g3PDbwmLM4',
    authDomain: 'dev-1Z.wiip.co',
    databaseURL: 'https://bricks-dev.firebaseio.com',
    projectId: 'bricks-dev',
    storageBucket: 'bricks-dev.appspot.com',
    messagingSenderId: '348295248891'
  }
  variables.imgixRedirect =
    'https://us-central1-bricks-dev.cloudfunctions.net/imgixRedirect'
  variables.acceptShare =
    'https://us-central1-bricks-dev.cloudfunctions.net/acceptShare'
  variables.braintree = {
    clientToken:
      'https://us-central1-bricks-dev.cloudfunctions.net/braintreeClientToken',
    cancel:
      'https://us-central1-bricks-dev.cloudfunctions.net/braintreeCancelSub',
    subscription:
      'https://us-central1-bricks-dev.cloudfunctions.net/braintreeSubscription'
  }
  variables.locales.en.translation = {
    baseUrl: 'http://localhost:3000',
    environment: 'local',
    environmentQualifier: ' (local)'
  }
  variables.analytics = { app: 'UA-117917294-2', website: 'UA-117917294-4' }
}

module.exports = variables
