import { Route } from 'react-router-dom'

// Route that uses the hash part of the url
// main routes use pathname
// extra routes can be navigated to on top of any main route
// used so that modals can overlay any route while still having a url

export default class ExtraRoute extends Route {
  computeMatch({ path, ...props }, router) {
    const { history } = router

    const location = { ...history.location }
    const updateLocation = hash => {
      const bits = hash.split('?')
      location.pathname = bits[0].replace(/^#/, '/')
      if (bits[1]) location.search = '?' + bits[1]
    }
    // if theres a hidden hash, use that
    if (history.location.state && history.location.state.hiddenHash) {
      updateLocation(history.location.state.hiddenHash)
    } else {
      updateLocation(history.location.hash)
      location.hash = ''
    }
    
    // ExtraRoute path can be specified with a # but super.computeMatch
    // will be sad about that so we remove it
    path = path.replace(/^#/, '/')
    return super.computeMatch({ ...props, path, location }, router)
  }
}
