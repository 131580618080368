import firebase from 'firebase/app'
import 'firebase/auth'
import React, { Component, Suspense } from 'react'
import ReactGA from 'react-ga'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { firebaseConfig, analytics } from 'variables'
import { RootLoader } from './RootLoader'
import { history } from './history'
import ExtraRoute from 'common/ExtraRoute'
import ReactModal from 'react-modal'
import 'i18n'
import './styles'

ReactModal.setAppElement('#root')

// ========================================================
// Firebase Init
// ========================================================
firebase.initializeApp(firebaseConfig)

// ========================================================
// Google Analytics
// ========================================================
ReactGA.initialize(
  [
    {
      trackingId: analytics.app,
      gaOptions: {
        name: 'app',
        userId: 123
      }
    },
    {
      trackingId: analytics.website,
      gaOptions: { name: 'website' }
    }
  ],
  { debug: false, alwaysSendToDefaultTracker: false }
)
ReactGA.set({
  environment:
    window.location.hostname === 'localhost'
      ? 'local'
      : process.env.REACT_APP_CONFIG
})

const App = React.lazy(() => import(/*webpackChunkName: 'app'*/ 'app'))
const Website = React.lazy(() =>
  import(/*webpackChunkName: 'website'*/ 'website')
)
const Auth = React.lazy(() => import(/*webpackChunkName: 'auth'*/ 'auth'))

// Stop Route being sad becasue it doesn't understand lazy
// TODO update router and remove
if (process.env.NODE_ENV !== 'production') {
  Route.propTypes = {}
}

export default class Root extends Component {
  state = { auth: undefined }
  componentDidMount() {
    this.stopWatchingAuth = firebase
      .auth()
      .onAuthStateChanged(auth => this.setState({ auth }))
  }
  componentWillUnmount() {
    this.stopWatchingAuth()
  }

  render() {
    const auth = this.state.auth
    if (auth === undefined) return <RootLoader step="Auth" />
    const loggedIn = auth && !auth.isAnonymous

    return (
      <Router history={history}>
        <Suspense fallback={<RootLoader step="Suspense" />}>
          <Route path="/signin" render={() => <Redirect to="/#signin" />} />
          <Route path="/signup" render={() => <Redirect to="/#signup" />} />
          <Route path="/forgot" render={() => <Redirect to="/#forgot" />} />

          <ExtraRoute
            path="#:action(signin|signup|forgot|terms)"
            component={Auth}
          />
          <Switch>
            {/* If not logged in root is Website */}
            {!loggedIn && <Route path="/" exact component={Website} />}
            <Route path="/details" component={Website} />
            <Route path="/curated" component={Website} />
            <Route path="/videos" component={Website} />
            <Route path="/terms" component={Website} />

            {/* App */}
            <Route component={App} />
          </Switch>
        </Suspense>
      </Router>
    )
  }
}
