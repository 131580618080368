const embeds = {
  vimeo: {
    minWidth: 200,
    urlPatterns: [
      /https?:\/\/(?:www\.)?vimeo\.com\/.+/,
      /https?:\/\/player\.vimeo\.com\/.+/
    ],
    oembed: url => 'https://vimeo.com/api/oembed.json?url=' + url
  },
  youtube: {
    minWidth: 200,
    urlPatterns: [
      /https?:\/\/(?:[^.]+\.)?youtube\.com\/watch\/?\?(?:.+&)?v=([^&]+)/,
      /https?:\/\/(?:[^.]+\.)?(?:youtu\.be|youtube\.com\/embed)\/([a-zA-Z0-9_-]+)/
    ]
  },
  instagram: {
    baseWidth: 450,
    measureAspect: true,
    urlPatterns: [
      /https?:\/\/instagram\.com\/p\/.*/,
      /https?:\/\/instagr\.am\/p\/.*/,
      /https?:\/\/www\.instagram\.com\/p\/.*/,
      /https?:\/\/www\.instagr\.am\/p\/.*/,
      /https?:\/\/instagram\.com\/p\/.*/,
      /https?:\/\/instagr\.am\/p\/.*/,
      /https?:\/\/www\.instagram\.com\/p\/.*/,
      /https?:\/\/www\.instagr\.am\/p\/.*/
    ],
    oembed: url =>
      `https://graph.facebook.com/v9.0/instagram_oembed?url=${
        url
      }&access_token=1716774751812677|0b4c4c5fbe58ee5db03c446961b21bf1`
  },
  twitter: {
    baseWidth: 450,
    measureAspect: true,
    urlPatterns: [/https?:\/\/twitter\.com\/.*\/status\/.*/]
  },
  soundcloud: {
    baseWidth: 450,
    measureAspect: true,
    urlPatterns: [/https?:\/\/soundcloud.com\/.*\/.*/]
  },
  flickr: {
    urlPatterns: [/https?:\/\/.*\.flickr\.com\/photos\/.*/]
  },
  reddit: {
    baseWidth: 450,
    measureAspect: true,
    urlPatterns: [
      /https?:\/\/reddit\.com\/r\/.*\/comments\/.*\/.*/,
      /https?:\/\/www\.reddit\.com\/r\/.*\/comments\/.*\/.*/
    ],
    processUrl: url => url.replace('www.', '')
  },
  dropbox: {
    urlPatterns: [
      /https?:\/\/www\.(dropbox\.com\/s\/.+\.(?:jpg|png|gif))/i,
      /https?:\/\/db\.tt\/[a-zA-Z0-9]+/
    ],
    processUrl: url =>
      url.replace('?dl=0', '').replace(/\.(JPG|PNG|GIF)/, x => x.toLowerCase())
  },
  spotify: {
    baseWidth: 300,
    urlPatterns: [
      /https?:\/\/open.spotify.com\/track\/.*/,
      /https?:\/\/open.spotify.com\/album\/.*/,
      /https?:\/\/open.spotify.com\/artist\/.*/,
      /https?:\/\/open.spotify.com\/user\/.*\/playlist\/.*/
    ],
    oembed: url => '/oEmbed?url=' + url,
    processData: data => {
      data.aspect = null
    }
  }
  // facebook: {
  //   urlPatterns: [
  //     /https?:\/\/www\.facebook\.com\/.*\/videos\/.*/,
  //     /https?:\/\/www\.facebook\.com\/video\.php/
  //   ]
  // }
}

// Required fields
// html
// provider_name
// almost required
// width
// height
// title
// thumbnail_url
// thumbnail_width
// thumbnail_height

module.exports = embeds
