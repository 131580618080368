import { injectGlobal } from 'emotion'
import regular_eot from '../media/fonts/SuisseIntl-Regular-WebS.eot'
import regular_woff from '../media/fonts/SuisseIntl-Regular-WebS.woff'
import regular_ttf from '../media/fonts/SuisseIntl-Regular-WebS.ttf'
import regular_svg from '../media/fonts/SuisseIntl-Regular-WebS.svg'
import medium_eot from '../media/fonts/SuisseIntl-Medium-WebS.eot'
import medium_woff from '../media/fonts/SuisseIntl-Medium-WebS.woff'
import medium_ttf from '../media/fonts/SuisseIntl-Medium-WebS.ttf'
import medium_svg from '../media/fonts/SuisseIntl-Medium-WebS.svg'
import semibold_eot from '../media/fonts/SuisseIntl-SemiBold-WebS.eot'
import semibold_woff from '../media/fonts/SuisseIntl-SemiBold-WebS.woff'
import semibold_ttf from '../media/fonts/SuisseIntl-SemiBold-WebS.ttf'
import semibold_svg from '../media/fonts/SuisseIntl-SemiBold-WebS.svg'

injectGlobal([
  // regular: 400
  // medium: 500
  // semibold: 600
  {
    '@font-face': {
      fontFamily: 'SuisseIntl',
      src: [
        `url("${regular_eot}")`,
        `url("${regular_eot}?#iefix") format("embedded-opentype"),
      url("${regular_woff}") format("woff"),
      url("${regular_ttf}") format("truetype"),
      url("${regular_svg}#Font") format("svg")`
      ],
      fontWeight: 400,
      fontStyle: 'normal'
    }
  },
  {
    '@font-face': {
      fontFamily: 'SuisseIntl',
      src: [
        `url("${medium_eot}")`,
        `url("${medium_eot}?#iefix") format("embedded-opentype"),
      url("${medium_woff}") format("woff"),
      url("${medium_ttf}") format("truetype"),
      url("${medium_svg}#Font") format("svg")`
      ],
      fontWeight: 500,
      fontStyle: 'normal'
    }
  },
  {
    '@font-face': {
      fontFamily: 'SuisseIntl',
      src: [
        `url("${semibold_eot}")`,
        `url("${semibold_eot}?#iefix") format("embedded-opentype"),
      url("${semibold_woff}") format("woff"),
      url("${semibold_ttf}") format("truetype"),
      url("${semibold_svg}#Font") format("svg")`
      ],
      fontWeight: 600,
      fontStyle: 'normal'
    }
  },

  {
    'html,body': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '1em',
      color: '#0B0B0B',
      fontFamily:
        "SuisseIntl, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif,'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',

      margin: 0,
      padding: 0,
      WebkitOverflowScrolling: 'touch',
      fontFeatureSettings: '"kern"',
      fontKerning: 'normal'
    },

    '*': {
      boxSizing: 'border-box'
    },

    '*:focus': {
      outline: 0 // remove blue on webkit
    }
  }
])
