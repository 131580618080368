import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'
import numeral from 'numeral'
import moment from 'moment'
import { locales } from 'variables'
import en from './locales/en.json'

moment.updateLocale('en', { relativeTime: en.relativeTime })

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',

    resources: {
      en: { translation: { ...en, ...locales.en.translation } }
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
      format: function(value, format, lng) {
        if (format.substr(0, 7) === 'numeral')
          return numeral(value).format(format.substr(8))
        if (format === 'moment:fromNow') return moment(value).fromNow()
        if (format.substr(0, 6) === 'moment')
          return moment(value).format(format.substr(7))
        return value
      }
    }
  })

export default i18n
